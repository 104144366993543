<template>
  <main class="AllocationsView">
    <div class="container">
      <header class="mb-3 d-flex">
        <h1 class="m-0">Allocations</h1>

        <div class="ms-auto AllocationsView__filters">
          <input
            ref="filter"
            type="text"
            placeholder="Filter allocations…"
            class="form-control form-control-sm w-50 mb-3"
            v-model="filter"
            v-if="isAuthAdmin || isAuthAgent"
          >

          <button class="btn btn-sm btn-primary ms-auto" @click="openForm()" v-if="isAuthAdmin && isAuthImpersonate">
            Add Allocation
          </button>
        </div>
      </header>

      <AppLoading v-if="loading" :spaced="false" />

      <AppEmptyList v-if="!loading && !allocations.length" />

      <section class="AllocationsView__list mb-5" v-if="!loading && allocations.length">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="AllocationsView__table table">
                <colgroup>
                  <col>
                  <col>
                  <col v-if="isAuthAdmin || isAuthAgent">
                  <col style="width: 100px">
                  <col style="width: 150px">
                  <col style="width: 150px">
                  <col style="width: 50px" v-if="authenticated.admin">
                </colgroup>
                <thead>
                  <tr>
                    <th>Exchange</th>
                    <th>Name</th>
                    <th v-if="isAuthAdmin || isAuthAgent">Client</th>
                    <th>Creation</th>
                    <th class="text-end">Amount</th>
                    <th>Status</th>
                    <th v-if="authenticated.admin" />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="allocation in filteredAllocations" :key="allocation.id" @click.stop="go(allocation.id)">
                    <td>
                      <ExchangeName :exchange="allocation.exchange" />
                    </td>
                    <td>
                      {{ allocation.name }}
                    </td>

                    <td v-if="isAuthAdmin || isAuthAgent">
                      {{ allocation.client.lastname }} {{ allocation.client.firstname }}
                    </td>

                    <td>
                      <time>{{ formatRelativeDate(allocation.created_at) }}</time>
                    </td>
                    <td class="text-end">
                      <strong class="text-primary">
                        ${{ formatDecimal(allocation.balance) }}
                      </strong>
                    </td>
                    <td>
                      <AllocationState :status="allocation.state" />
                    </td>
                    <td class="text-end" v-if="authenticated.admin">
                      <DropdownMenu align="end">
                        <i class="bi bi-gear" />

                        <template #menu>
                          <DropdownItem @click="openForm(allocation)">
                            Edit
                          </DropdownItem>
                          <DropdownItem @click="reset(allocation)">
                            Reset
                          </DropdownItem>
                        </template>
                      </DropdownMenu>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  import AllocationState from '@/components/AllocationState'
  import AppEmptyList from '@/components/AppEmptyList'
  import AppLoading from '@/components/AppLoading'
  import DropdownItem from '@/components/base/DropdownItem'
  import DropdownMenu from '@/components/base/DropdownMenu'
  import ExchangeName from '@/components/ExchangeName'
  import ModalAllocationForm from '@/components/ModalAllocationForm'
  import ModalConfirm from '@/components/ModalConfirm'

  import allocationService from '@/services/allocations.service'

  import useModal from '@/plugins/modal/useModal'
  import { formatRelativeDate } from '@/utils/date'
  import { formatDecimal } from '@/utils/number'
  import { filterCollection } from '@/utils/filters'
  import { mapState, mapGetters } from 'vuex'

  export default {
    components: {
      AllocationState,
      AppEmptyList,
      AppLoading,
      ExchangeName,
      DropdownItem,
      DropdownMenu
    },

    setup() {
      return {
        modal$: useModal()
      }
    },

    data() {
      return {
        filter: '',
        loading: true,
        allocations: null,
        exchange: null
      }
    },

    computed: {
      ...mapState('auth', [
        'authenticated',
        'impersonated'
      ]),

      ...mapGetters('auth', [
        'isAuthAdmin',
        'isAuthAgent',
        'isAuthImpersonate'
      ]),

      filteredAllocations() {
        return filterCollection(this.allocations, this.filter, [ 'client.firstname', 'client.lastname', 'name' ])
      }
    },

    mounted() {
      this.load()
    },

    methods: {
      formatRelativeDate,
      formatDecimal,

      async load() {
        this.loading = true
        this.allocations = await allocationService.fetch()
        this.loading = false
      },

      go(id) {
        this.$router.push({ name: 'allocationDetails', params: { id } })
      },

      openForm(allocation) {
        this.modal$
          .open(ModalAllocationForm, {
            closeOnBackdrop: false,
            scrollable: true,
            props: {
              allocation
            }
          })
          .onClose((response) => {
            if ([ 'DELETED', 'SAVED' ].includes(response)) {
              this.load()
            }
          })
      },

      reset(allocation) {
        const title = 'Reset allocation'

        this.modal$
          .open(ModalConfirm, {
            props: {
              title: title,
              text: 'Please confirm resetting  <b>' + allocation.name + '</b>'
            }
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await allocationService.reset(allocation.id)
                this.toast$.success(title, 'The allocation has been reset.')
              } catch (e) {
                console.error(e)
                this.toast$.danger(title, 'Something went wrong. Try later.')
              }
            }
          })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  .AllocationsView__filters
    display: flex
    align-items: center
    justify-content: center
    gap: 1em

    input.form-control
      height: 100%
      margin: 0 !important
      width: 150px !important

  .AllocationsView__table
    margin-bottom: 150px

    tr
      vertical-align: middle
      cursor: pointer

    th, td
      white-space: nowrap

  .AllocationsView__table__logo
    width: 32px
    height: 32px
</style>
