<template>
  <div class="AllocationState">
    <div class="AllocationState--on" v-if="status === 'on'">
      <i class="bi bi-circle-fill" />
      Connected
    </div>

    <div class="AllocationState--off" v-if="status === 'off'">
      <i class="bi bi-circle" />
      Disconnected
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      status: {
        type: String,
        default: 'off'
      }
    }
  }
</script>

<style lang="sass" scoped>
 @import '@/sass/styles.vars.all.sass'

.AllocationState
  display: flex
  align-items: center
  text-transform: uppercase
  font-size: .75rem

  i
    font-size: .75rem
    margin-right: .5em

  &--on,
  &--off
    padding: .2em .5em
    border-radius: 2em

  &--on
    background: tint-color($success, 90)
    color: $success

  &--off
    background: tint-color($gray-500, 90)
    color: $gray-500

</style>

